import { css } from "@emotion/react";
import { theme } from "@comunidadfeliz/theme";
import {
  MOBILE_BREAKPOINT,
  SMALL_MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "styles/breakpoints";

export const body = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(56px, auto) 1fr;
  height: 100dvh;
`;

export const navbar = css`
  display: flex;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  background: var(--color-navbar);
  flex-wrap: wrap;
  @media (max-width: ${SMALL_MOBILE_BREAKPOINT}) {
    padding: 10px 10px;
    gap: 5px;
  }
`;

export const logo = css`
  cursor: pointer;
`;

export const footer = (alignRight?: boolean) => css`
  position: fixed;
  display: flex;
  align-items: center;
  right: 0;
  bottom: 0;
  justify-content: center;
  padding: 0 32px 16px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  gap: 8px;
  z-index: -10;
  color: ${theme.colors.gray.quaternary};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    ${!alignRight && `padding: 0 0 16px;`}
  }
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    justify-content: ${alignRight ? "flex-end" : "center"};
  }
`;

export const title = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${theme.colors.gray.secondary};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 20px;
  }
`;

export const subtitle = css`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.25px;

  color: ${theme.colors.gray.tertiary};
`;

export const headerSection = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const header = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${theme.colors.gray.primary};
`;

export const stepperHeader = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  color: ${theme.colors.gray.primary};
  gap: 16px;
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    justify-items: center;
    grid-template-columns: 1fr;
  }
`;

export const helpContainer = css`
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: none;
  }
`;

export const helpTitle = css`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
`;

export const footerContainer = css`
  display: none;
  align-items: center;
  gap: 32px;
  text-align: center;
  & p {
    text-align: center;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    display: flex;
    flex-direction: column;
  }
`;

export const successCreateAccount = css`
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  margin-inline: auto;
  padding: 32px 0;
  gap: 32px;
  @media (min-width: ${MOBILE_BREAKPOINT}) {
    padding: 32px 20px;
  }
  & h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    align-self: flex-start;
    color: ${theme.colors.gray.primary};
  }
  & > h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    color: ${theme.colors.gray.primary};
  }
  & > p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: ${theme.colors.gray.primary};
  }
`;

export const successIcon = css`
  margin-inline: auto;
`;

export const successTitle = css`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  align-self: center;
  margin: auto;
  text-align: center;
  color: ${theme.colors.gray.primary};
`;

export const titleContainer = css`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  color: ${theme.colors.gray.primary};
  & > h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    align-self: flex-start;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    gap: 8px;
    & > h1 {
      font-size: 20px;
      line-height: 24px;
      align-self: center;
    }
  }
`;

export const titleVerticalContainer = css`
  ${titleContainer};
  @media (max-width: ${MOBILE_BREAKPOINT}) {
    align-items: center;
    flex-direction: column;
    & > h1 {
      text-align: center;
    }
  }
`;

export const acountMenu = css`
  z-index: 10000;
`;

export const accountMenuButton = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  text-transform: capitalize;
  color: ${theme.colors.white};
  & .arrow {
    transition: all 0.2s ease-in-out;
  }
  &[data-state="open"] {
    & .arrow {
      transform: rotate(180deg);
    }
  }
  &[data-state="closed"] {
    & .filledIcon {
      display: none;
    }
  }
  & > h1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.25px;
  }
`;

export const accountMenu = css`
  [data-state="open"] {
    & .arrow {
      transform: rotate(180deg);
    }
  }
`;

export const arrowIcon = (open?: boolean) => css`
  transform: ${open ? "rotate(180deg)" : "rotate(0deg)"};
`;

export const homeIcon = css`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.colors.gray.primary};
  cursor: pointer;
  white-space: nowrap;
  width: 96px;
  height: 30px;
  justify-content: flex-end;
  &:hover {
    color: var(--color-links);
    > svg > path {
      fill: var(--color-links);
    }
  }
  & > svg {
    width: 15px;
    height: 19.33px;
  }
`;

// pages styles

export const indexPage = css`
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  margin-inline: auto;
  padding: 28px 20px;
`;

export const cfPage = css`
  display: flex;
  flex-direction: column;
  max-width: 1120px;
  width: 100%;
  margin-inline: auto;
  padding: 28px 20px;
  & > header {
    display: flex;
    justify-content: space-between;
    & > h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: ${theme.colors.gray.primary};
    }
  }
`;

export const successPage = css`
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 100%;
  margin-inline: auto;
  padding: 32px 20px;
  gap: 32px;
`;

export const loginPage = css`
  display: flex;
  flex-direction: column;
  max-width: 530px;
  width: 100%;
  margin-inline: auto;
  padding: 32px 20px;
  gap: 32px;
`;

export const bankDataPage = css`
  ${loginPage};
  max-width: 680px;
`;

export const landingPage = css`
  display: flex;
  width: 100%;
  min-height: 100dvh;
  @media (max-width: ${TABLET_BREAKPOINT}) {
    flex-direction: column;
    background-color: var(--color-blue);
    > div {
      display: none;
    }
  }
  @media (min-width: ${TABLET_BREAKPOINT}) {
    > div {
      flex-shrink: 0;
      width: 80vh;
    }
    > section {
      place-self: center;
      width: 60%;
    }
  }
`;

export const onboardingPage = css`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  width: 100%;
  margin-inline: auto;
  padding: 32px 20px;
  gap: 32px;
`;

export const cfPaymentData = css`
  display: flex;
  flex-direction: column;
  max-width: 570px;
  width: 100%;
  margin-inline: auto;
  padding: 32px 20px;
  gap: 32px;
  & > header {
    display: flex;
    justify-content: space-between;
    & > h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      color: ${theme.colors.gray.primary};
    }
    & > div {
      width: auto !important;
    }
  }
`;

export const formSection = css`
  gap: 32px;
  display: flex;
  flex-direction: column;
  & > article {
    counter-increment: section;
    & > h2 {
      ::before {
        content: counter(section) ". ";
      }
    }
  }
`;

export const propertiesPage = css`
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  gap: 12px;
`;

export const landingCFPage = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 45px;
  padding-top: 32px;
  min-height: 100dvh;
  padding: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    max-width: 530px;
    gap: 32px;
    align-items: center;
    background-color: ${theme.colors.white};
    padding: 32px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: ${theme.colors.gray.primary};
    h1 {
      font-weight: 700;
    }
    > button {
      width: auto;
    }
  }
`;

export const link = css`
  color: ${theme.colors.blue.primary};
  font-weight: 700;
`;

export const disclaimer = css`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: ${theme.colors.gray.secondary};
`;
