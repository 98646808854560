/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from "hooks";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { logout } from "features/owner/index.slice";
import useBciScriptLoader from "features/bci/useBciScriptLoader";
import useRole from "hooks/useRole";
import Modals from "modals/Modals";
import Navbar from "./Navbar";
import * as styles from "./index.styles";
import FooterCF from "./FooterCF";
import ExpiryModal from "./ExpiryModal";

export type Props = {
  isProtectedRoute?: boolean;
  navbar?: boolean;
} & React.PropsWithChildren;

export default function Body({
  children,
  isProtectedRoute,
  navbar = true,
}: Props) {
  const {
    isAuthenticated,
    redirectToLogin,
    cfUserRedirect,
    credentials,
    expired,
    routesWithoutLoginButton,
    asPath,
  } = useAuth();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { cfUser } = useAppSelector((state) => state.lessee_register_property);
  useRole();
  useBciScriptLoader();

  const isRouteWithoutModal = routesWithoutLoginButton.some((route) =>
    asPath.includes(route)
  );

  useEffect(() => {
    if (isProtectedRoute && !isAuthenticated) {
      if (cfUser) {
        cfUserRedirect.login();
      } else {
        redirectToLogin();
      }
    }
  }, [isAuthenticated]);

  const handleClose = () => {
    if (isAuthenticated) {
      dispatch(logout());
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (expired && isAuthenticated && !isRouteWithoutModal) {
      setOpen(true);
    }
  }, [credentials]);

  return (
    <div css={styles.body}>
      <ExpiryModal open={open} onOpenChange={handleClose} />
      <Modals />
      {navbar && <Navbar />}
      {children}
      <FooterCF alignRight />
    </div>
  );
}
